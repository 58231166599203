import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const useDriveLoader = () => {
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )

  const creatorEmail = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.creatorEmail,
  )
  const creatorFullName = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.creatorFullName,
  )

  const {
    creatorEmail: modifiedDriveCreatorEmail,
    creatorFullName: modifiedDriveCreatorFullName,
  } = useSelector((state: RootState) => {
    return {
      creatorEmail: state.drive.driveToModify?.creatorEmail,
      creatorFullName: state.drive.driveToModify?.creatorFullName,
    }
  })

  const rights = useSelector((state: RootState) => state.drive.driveRights)

  const auth = useSelector((state: RootState) => state.auth)
  const fileRights = rights?.fileRights || creatorEmail === auth.email
  const folderRights = rights?.folderRights || creatorEmail === auth.email
  const adminRights = rights?.adminRights || creatorEmail === auth.email
  const modifiedDriveAdminRights = useSelector(
    (state: RootState) => state.drive.driveModifyForm.isAdmin,
  )

  const selectedFilesFolders = useSelector(
    (state: RootState) => state.drive.selectedFilesFolders,
  )

  const driveEncryptKey = useSelector(
    (state: RootState) =>
      state.drive.drives?.find((drive) => drive.id === selectedDriveFolder?.driveId)
        ?.fileEncryptKey,
  )

  return {
    modifiedDriveCreatorEmail,
    modifiedDriveCreatorFullName,
    modifiedDriveAdminRights,
    creatorEmail,
    creatorFullName,
    fileRights,
    folderRights,
    adminRights,
    selectedDriveFolder,
    selectedFilesFolders,
    driveEncryptKey,
  }
}
