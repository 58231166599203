import React, { useState, useEffect } from 'react'
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Switch,
  Table,
} from 'antd'
import { useTranslation } from 'react-i18next'
import './groups.scss'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useAttemptsListener } from 'auxasphere-react-kit'
import {
  attemptAddParticipant,
  attemptAttachParticipant,
  fetchOrganizationSummary,
  fetchUsers,
  idleAddParticipantStatus,
  idleAttachParticipantStatus,
  idleUsers,
} from '../Organisation/redux/organisationSlice'
import { OrganizationParticipant } from '../Organisation/OrganizationInterface'
import SelectLanguage from '../../components/Select/SelectLanguage'
import { useNavigate } from 'react-router-dom'
import i18n from '../../i18n'
import { useToastContext } from '../../components/Toast/ToastContext'

export interface Props {
  attachParticipantModalOpen: boolean
  addParticipantModalOpen: boolean
  setAttachParticipantModalOpen: (open: boolean) => void
  setAddParticipantModalOpen: (open: boolean) => void
}

function AddUserModal({
  attachParticipantModalOpen,
  addParticipantModalOpen,
  setAttachParticipantModalOpen,
  setAddParticipantModalOpen,
}: Props) {
  const { i18n } = useTranslation()
  const { t } = useTranslation('organisation')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()
  const organisation = useSelector((state: RootState) => state.organisation)
  const [pageDetails, setPageDetails] = useState<{ page: number; usersPerPage: number }>({
    page: 1,
    usersPerPage: 10,
  })
  const [addParticipantForm] = Form.useForm<OrganizationParticipant>()
  const [attachExistingUser, setAttachExistingUser] = useState<string | undefined>()
  const [attachParticipantForm] = Form.useForm()
  const [existingUserToAttach, setExistingUserToAttach] = useState<
    OrganizationParticipant | undefined
  >()

  /**
   *
   */
  useEffect(() => {
    //TODO: refactoring (don't fetch previously fetched pages)
    updateUsers()
    return () => {
      dispatch(idleUsers())
    }
  }, [pageDetails])

  useAttemptsListener([
    [
      organisation.addParticipantStatus,
      {
        error: () => {
          switch (organisation.addParticipantError) {
            case 'USER_ALREADY_EXIST':
              attachParticipantForm.setFieldsValue({
                email: organisation.userToAttach?.email,
                firstName: organisation.userToAttach?.firstName,
                lastName: organisation.userToAttach?.lastName,
                admin: organisation.userToAttach?.organizationAdmin,
                lang: organisation.userToAttach?.lang,
              })
              setAttachExistingUser(existingUserToAttach?.email)
              closeAddParticipantModal()
              break
            case 'USER_ALREADY_ATTACHED_TO_THIS_ORGANIZATION':
              ToastOpen({
                message: t(
                  'Could not attach participant. He/she is already attached to your organisation.',
                ),
                type: 'error',
              })
              break
            case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANIZATION':
              ToastOpen({
                message: t(
                  'Could not attach participant. He/she is already attached to another organisation.',
                ),
                type: 'error',
              })
              break
            case 'NOT_ENOUGH_LICENCES':
              ToastOpen({
                message: t(
                  "You don't have enough licences. For more licences, please contact sales support.",
                ),
                type: 'error',
              })
              break
            default:
              ToastOpen({
                message: t('An error occurred.'),
                type: 'error',
              })
              break
          }
        },
        success: () => {
          ToastOpen({
            message: t('The user has been successfully added.'),
            type: 'success',
          })
          updateUsers()
          closeAddParticipantModal()
        },
      },
      () => dispatch(idleAddParticipantStatus()),
    ],
    [
      organisation.attachParticipantStatus,
      {
        error: () => {
          switch (organisation.attachParticipantError) {
            case 'USER_ALREADY_ATTACHED_TO_ORGANISATION':
              ToastOpen({
                message: t(
                  'Could not attach participant. He/she is already attached to your organisation.',
                ),
                type: 'error',
              })
              break
            case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANISATION':
              ToastOpen({
                message: t(
                  'Could not attach participant. He/she is already attached to another organisation.',
                ),
                type: 'error',
              })
              break
            default:
              ToastOpen({
                message: t(
                  'An error occurred. It may be that he/she does not have an account on the platform.',
                ),
                type: 'error',
              })
              break
          }
        },
        success: () => {
          ToastOpen({
            message: t('The user has been successfully attached to your organisation.'),
            type: 'success',
          })
          closeAttachParticipantModal()
          updateUsers()
        },
      },
      () => dispatch(idleAttachParticipantStatus()),
    ],
  ])

  /**
   * addParticipant to organization
   * @param values : OrganizationParticipant
   */
  function addParticipant(values: OrganizationParticipant) {
    if (!organisation.organisation?.id) {
      throw new Error(
        'something went wrong: organisation.id = ' + organisation.organisation?.id,
      )
    }
    setExistingUserToAttach({
      email: values['email'],
      firstName: values['firstName'],
      lastName: values['lastName'],
      lang: values['lang'],
      organizationAdmin: values['organizationAdmin'] || false,
      groups: values['groups'] || [],
    })
    dispatch(
      attemptAddParticipant({
        organizationId: organisation.organisation.id,
        user: {
          email: values['email'],
          firstName: values['firstName'],
          lastName: values['lastName'],
          lang: values['lang'],
          organizationAdmin: values['organizationAdmin'] || false,
          groups: values['groups'] || [],
        },
      }),
    )
    navigate('/organization')
  }

  /**
   *
   */
  function closeAddParticipantModal() {
    addParticipantForm.resetFields()
    setAddParticipantModalOpen(false)
  }

  /**
   *
   */
  function closeAttachParticipantModal() {
    attachParticipantForm.resetFields()
    setAttachParticipantModalOpen(false)
    setAttachExistingUser(undefined)
  }

  /**
   *
   */
  function updateUsers() {
    //TODO: update licenses without fetchOrganizationSummary
    dispatch(
      fetchUsers({
        page: pageDetails.page,
        usersPerPage: pageDetails.usersPerPage,
      }),
    )
    if (organisation.organisation) {
      dispatch(fetchOrganizationSummary({ organizationId: organisation.organisation.id }))
    }
  }

  /**
   *
   * @param values
   */
  function attachParticipant(values: OrganizationParticipant) {
    if (!organisation.organisation?.id) {
      throw new Error(
        'something went wrong: organisation.id = ' + organisation.organisation?.id,
      )
    }
    dispatch(
      attemptAttachParticipant({
        organizationId: organisation.organisation.id,
        user: {
          email: values['email'],
          firstName: values['firstName'],
          lastName: values['lastName'],
          lang: values['lang'],
          organizationAdmin: values['organizationAdmin'] || false,
          groups: values['groups'] || [],
        },
      }),
    )
  }

  return (
    <>
      <Modal
        centered
        title={t('Add user')}
        styles={{ body: { padding: '1em 2em' } }}
        open={addParticipantModalOpen}
        onCancel={closeAddParticipantModal}
        footer={
          <div className="signup-modal-footer">
            <Button onClick={closeAddParticipantModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="add-participant-form"
              loading={organisation.addParticipantStatus === 'loading'}
            >
              {t('Add user')}
            </Button>
          </div>
        }
      >
        <Form
          id="add-participant-form"
          form={addParticipantForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addParticipant}
          scrollToFirstError={true}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[
              { required: true, message: t('Please input an email') },
              {
                type: 'email',
                message: t('The format of the email does not comply', { ns: 'common' }),
                validateTrigger: 'onSubmit',
                transform: (value) => value.trim(),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[
              { required: true, message: t('Please input a first name') },
              { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || '' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[
              { required: true, message: t('Please input a last name') },
              { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || '' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') }]}
            initialValue={i18n.language}
          >
            <SelectLanguage />
          </Form.Item>

          <Form.Item
            label={t('Admin')}
            name="organizationAdmin"
            valuePropName="checked"
            colon={false}
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        styles={{ body: { padding: '1em 2em' } }}
        title={t('Attach user')}
        open={attachParticipantModalOpen || attachExistingUser != undefined}
        onCancel={closeAttachParticipantModal}
        footer={
          <div className="signup-modal-footer">
            <Button onClick={closeAttachParticipantModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="attach-participant-form"
              loading={organisation.attachParticipantStatus === 'loading'}
            >
              {t('Attach user')}
            </Button>
          </div>
        }
      >
        {
          <Alert
            message={
              <div className="text-center">
                {t('This account already exists.')}
                <br />
                {t('Do you want to attach him to his organisation?')}
              </div>
            }
            className="mb-1rem"
            type="warning"
          />
        }

        <Form
          id="attach-participant-form"
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={attachParticipant}
          form={attachParticipantForm}
          scrollToFirstError={true}
        >
          <Form.Item label={t('Email')} name="email">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[
              { required: true, message: t('Please input a first name') },
              { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || '' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[
              { required: true, message: t('Please input a last name') },
              { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || '' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') }]}
          >
            <SelectLanguage />
          </Form.Item>
          <Form.Item label={t('Admin')} name="organizationAdmin" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

// AddUserModal.displayName = 'AddUserModal'
export default AddUserModal
