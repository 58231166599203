import React, { memo, MouseEvent, useEffect, useRef, useState } from 'react'
import { Card, Dropdown, Button, Spin, Tooltip } from 'antd'
import './ApplicationCard.scss'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  deleteFavorite,
  FavoriteInterface,
  setFavoriteToModify,
} from './redux/applicationsSlice'
import ModalConfirmation, {
  ModalConfirmationInterface,
} from '../../components/Modal/ModalConfirmation'
import { limitText } from '../../utils/Utils'
import { MenuProps } from 'antd/lib'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { InternApplicationInterface } from './Applications'

interface Props {
  application: FavoriteInterface | InternApplicationInterface
  intern: boolean
}

function ApplicationCard({ application, intern }: Props) {
  const { t } = useTranslation('applications')
  const dispatch = useDispatch<AppDispatch>()
  const deleteFavoriteConfirmation = useRef<ModalConfirmationInterface>(null)
  const deleteFavoriteStatus = useSelector(
    (state: RootState) => state.applications.deleteFavoriteStatus,
  )

  const handleDeleteFavorite = () => {
    deleteFavoriteConfirmation.current?.openModal()
  }
  const confirmDeleteFavorite = () => dispatch(deleteFavorite({ id: application.id }))

  function openBlank() {
    if ('url' in application) {
      window.open(application.url, '_blank')
    }
  }

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <div
          className="cursor-pointer link d-flex d-flex-middle"
        >
          <AiOutlineEdit className="default-text-color" size="1.2rem" />
          <div className="dropdown-icon-margin-left">{t('Edit', { ns: 'common' })}</div>
        </div>
      ),
      onClick: () => dispatch(setFavoriteToModify(application as FavoriteInterface))
    },
    {
      key: 'delete',
      label: (
        <div
          className="cursor-pointer link d-flex d-flex-middle"
        >
          <AiOutlineDelete className="default-text-color" color="#ff4d4f" size="1.2rem" />
          <div className="dropdown-icon-margin-left error-color">
            {t('Delete', { ns: 'common' })}
          </div>
        </div>
      ),
      onClick: () => handleDeleteFavorite()
    },
  ]

  return (
    <>
      <Tooltip title={application.name}>
        <div className="ApplicationCard">
          <Card className="card" hoverable onClick={openBlank}>
            {application.icon ? (
              <img
                src={application.icon}
                alt={`${application.name} icon`}
                className={`logo ${intern ? 'logo-intern' : 'logo-extern'}`}
                onError={(e) => console.error(e)}
              />
            ) : (
              <div className="d-flex d-flex-center d-flex-middle">
                <h1 className="empty-favicon">
                  {Array.from(application.name)[0].toLocaleUpperCase()}
                </h1>
              </div>
            )}
          </Card>
          <h3 className="name">{application.name}</h3>
          {!intern && (
            <div className="dropdown-container">
              <Dropdown menu={{ items }} trigger={['click']}>
                <Button
                  className="dropdown-toggle"
                  type="text"
                  color="white"
                  icon={<BiDotsVerticalRounded size="1.5em" />}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              </Dropdown>
            </div>
          )}
        </div>
      </Tooltip>
      <ModalConfirmation
        ref={deleteFavoriteConfirmation}
        title={t('Delete bookmark')}
        onOk={confirmDeleteFavorite}
        okText={t('Yes', { ns: 'common' })}
        confirmLoading={deleteFavoriteStatus === 'loading'}
        onCancel={() => deleteFavoriteConfirmation.current?.close()}
        cancelText={t('No', { ns: 'common' })}
      >
        <p>
          <Trans
            i18nKey={'DELETE_BOOKMARK'}
            shouldUnescape={true}
            ns="applications"
            values={{ name: limitText(application.name) }}
            components={{ bold: <strong /> }}
          />
        </p>
      </ModalConfirmation>
    </>
  )
}

export default ApplicationCard
