import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WebSocketConnectionContext } from '../../components/ChatComponent/WebsocketConnection/WebSocketConnectionContext'
import ChatList from '../../components/ChatComponent/Chat/ChatList'
import Chat from '../../components/ChatComponent/Chat/Chat'
import { Button, Drawer, Spin } from 'antd'
import useChatInitialization from '../../utils/hooks/useChatInitialization'
import './Messages.scss'
import { TbListTree, TbMessageOff } from 'react-icons/tb'
import { TiMessages } from 'react-icons/ti'
import Call from '../../components/ChatComponent/Call/Call'

function Messages() {
  const { t } = useTranslation(['chat'])
  const jwt = useSelector((state: RootState) => state.auth.jwt) || ''
  const email = useSelector((state: RootState) => state.auth.email) || ''
  const organisation = useSelector((state: RootState) => state.organisation.organisation)
  const currentSquadId = organisation?.id || ''
  const selectDefaultChatOnLoad = false

  const { wsRef, squad, currentChatId, calling, chatStatus } = useChatInitialization({
    currentSquadId,
    jwt,
    email,
    selectDefaultChatOnLoad,
  })

  const [openResponsiveDrawerMenu, setOpenResponsiveDrawerMenu] = useState(false)

  const renderChatMenuDrawer = () => (
    <Drawer
      title="Team"
      open={openResponsiveDrawerMenu}
      placement="left"
      className="right-side-drawer"
      onClose={() => setOpenResponsiveDrawerMenu(false)}
    >
      <>
        {chatStatus === 'ACTIVE' && (
          <ChatList
            currentSquadId={squad.id}
            setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
          />
        )}
      </>{' '}
    </Drawer>
  )

  return (
    <WebSocketConnectionContext.Provider value={wsRef.current}>
      <Layout
        title="Team"
        headerTitle={<h1 className="title">{t('Team')}</h1>}
        className="messages_page"
        menu={
          <>
            {chatStatus === 'ACTIVE' && (
              <ChatList
                currentSquadId={squad.id}
                setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
              />
            )}
          </>
        }
        content={
          <>
            <div className="messages_content">
              {!selectDefaultChatOnLoad &&
                !currentChatId &&
                chatStatus !== 'MISSING_SQUAD' && (
                  <div className="chat_messages">
                    <Button
                      className="drawer-menu-responsive-button"
                      onClick={() => setOpenResponsiveDrawerMenu(true)}
                      size="middle"
                    >
                      <TbListTree width="5rem" />
                    </Button>
                    <div className="empty-container">
                      <TiMessages size="4em" />
                      <div style={{ display: 'grid', justifyItems: 'center' }}>
                        <h1 className="text-color-default">
                          {t('Start a conversation')}
                        </h1>
                        <h2 className="text-color-default">
                          {t('Select a conversation to start messaging')}
                        </h2>
                      </div>
                      {chatStatus === 'LOADING' && (
                        <div className="d-flex d-flex-center d-flex-middle 2rem">
                          <Spin size="large" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {chatStatus === 'ACTIVE' && currentChatId && (
                <>
                  {calling ? (
                    <Call
                      wsRef={wsRef}
                      currentSquadId={squad.id}
                      currentChatId={currentChatId}
                    />
                  ) : (
                    <Chat
                      currentSquadId={squad.id}
                      currentChatId={currentChatId}
                      setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                    />
                  )}
                </>
              )}
              {chatStatus === 'MISSING_SQUAD' && (
                <div className="chat_disabled">
                  <TbMessageOff size="2.5em" />
                  <h3>{t('Chat is not activated for your organisation.')}</h3>
                </div>
              )}
            </div>
            {renderChatMenuDrawer()}
          </>
        }
      />
    </WebSocketConnectionContext.Provider>
  )
}

export default Messages
