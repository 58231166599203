import { createAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../../../store'
import {
  ChatActions,
  Message,
  MessageType,
  WebsocketConnection,
} from '../WebsocketConnection'
import { setConnected } from './callWebsocketSlice'

interface WebRTCSignalingMessage extends Message {
  from: string
  data: string
}

export const websocketWebRTCSignalingAction = createAction<WebRTCSignalingMessage>(
  'websocket-connection/webrtc-signaling',
)

export class CallWebsocketConnection extends WebsocketConnection {
  private _peerEmail: string

  constructor(dispatch: AppDispatch, jwt: string, me: string, peerEmail: string) {
    super(dispatch, jwt, me)
    this._peerEmail = peerEmail
  }

  public connect() {
    const url = `${process.env.REACT_APP_CHAT_BASE_WS_URL}/call?token=${this._jwt}&squad=${this._peerEmail}`
    super.connect(url)
  }

  public updateConnectionState(connected: boolean) {
    this._dispatch(setConnected(connected))
  }

  public handleMessage(data: Message) {
    try {
      switch (data.type) {
        case MessageType.WEBRTC_SIGNALING:
          this._dispatch(websocketWebRTCSignalingAction(data as WebRTCSignalingMessage))
          break

        case MessageType.HEARTBEAT:
          this.updateDisconnectTimeout()
          break

        default:
          throw new Error('Unknown message type: ' + data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  public sendWebRTCSignaling(to: string, data: string) {
    this.send(
      JSON.stringify({
        type: ChatActions.WEBRTC_SIGNALING,
        payload: { to, data },
      }),
    )
  }

  public updateDisconnectTimeout() {
    clearTimeout(this.disconnectTimeout)

    this.disconnectTimeout = setTimeout(() => {
      this.connect()
    }, 5000)
  }

  public send(data: string) {
    try {
      this._ws?.send(data)
    } catch (e) {
      console.error(e, data)
    }
  }
}
