import React, { ReactNode, useState, useEffect } from 'react'
import {
  Col,
  Select,
  Form,
  Tooltip,
  Button,
  Row,
  AutoComplete,
  Divider,
  Card,
} from 'antd'
import {
  DriveUserParticipant,
  DriveRole,
  DriveGroupParticipant,
  setParticipants,
  setGroups,
} from '../../../features/Drives/redux/drivesSlice'
import { useTranslation } from 'react-i18next'
import { BiInfoCircle } from 'react-icons/bi'
import { IoIosAdd } from 'react-icons/io'
import { FormInstance } from 'antd/lib'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import DriveUsers from './DriveUsers'
import { searchEntities } from '../../../features/Organisation/OrganisationService'
import DriveGroups from './DriveGroups'

interface Props {
  driveForm: FormInstance
  newDrive: boolean
}

export interface SelectOption {
  id: string //email for user & id for group
  value: string
  label: string | JSX.Element
  type: 'user' | 'group' | 'title' | 'invite'
  firstName?: string
  lastName?: string
}

export interface Option {
  label: React.ReactNode
  options: SelectOption[]
}

function DriveFormAddParticipant({ driveForm, newDrive }: Props) {
  const { t } = useTranslation('drives')
  const jwt = useSelector((state: RootState) => state.auth.jwt) || ''
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)
  const organisationId = useSelector(
    (state: RootState) => state.organisation.organisation?.id,
  )
  const participants = useSelector(
    (state: RootState) => state.drive.driveModifyForm.participants,
  )
  const groups = useSelector((state: RootState) => state.drive.driveModifyForm.groups)
  const isAdmin = useSelector((state: RootState) => state.drive.driveModifyForm.isAdmin)

  const [options, setOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<SelectOption>()

  const renderTitle = (title: string) => <span>{title}</span>

  const onSearch = async (q: string) => {
    if (organisationId) {
      setOptions([])
      try {
        const entities = await searchEntities({
          jwt,
          q,
          organisationId,
          excludeEmails: [...(participants?.map((p) => p.email) || []), email || ''],
          excludeGroupIds: groups?.map((g) => g.id) || [],
        })

        const userSelectOptions: SelectOption[] = entities.users.map(
          ({ email, firstName, lastName }) => ({
            id: email,
            label: `${firstName} ${lastName} (${email})`,
            value: email,
            type: 'user',
            firstName,
            lastName,
          }),
        )

        const groupSelectOptions: SelectOption[] = entities.groups.map(
          ({ id, name }) => ({
            id,
            label: name,
            value: name,
            type: 'group',
          }),
        )

        const optionsFull: Option[] = [
          {
            label: renderTitle(t('Users', { ns: 'layout' })),
            options: userSelectOptions,
          },
          {
            label: renderTitle(t('Groups')),
            options: groupSelectOptions,
          },
        ]

        setOptions(optionsFull)
      } catch (error) {
        console.error('Error searching entities:', error)
      }
    }
  }

  const onSelect = (value: string, option: any) => {
    setSelectedOption(option)
  }

  const addDriveParticipant = () => {
    const role = driveForm.getFieldValue('role')

    if (!selectedOption) return

    const participantAlreadyAdded =
      selectedOption.type === 'user'
        ? participants.some((participant) => participant.email === selectedOption?.id)
        : groups.some((group) => group.id === selectedOption?.id)

    if (participantAlreadyAdded) return

    const participant =
      selectedOption.type === 'user'
        ? {
            email: selectedOption.value,
            fullName: `${selectedOption.firstName} ${selectedOption.lastName}`,
          }
        : {
            id: selectedOption.id,
            name: selectedOption.value,
          }

    const rights = {
      adminRights: role === DriveRole.ADMIN,
      folderRights: role === DriveRole.FOLDERS_FILES || role === DriveRole.ADMIN,
      fileRights:
        role === DriveRole.FILES ||
        role === DriveRole.FOLDERS_FILES ||
        role === DriveRole.ADMIN,
    }

    if (selectedOption.type === 'user') {
      dispatch(
        setParticipants([
          ...participants,
          { ...participant, ...rights } as DriveUserParticipant,
        ]),
      )
    } else {
      dispatch(
        setGroups([...groups, { ...participant, ...rights } as DriveGroupParticipant]),
      )
    }

    driveForm.resetFields(['participant'])

    const updatedOptions = options.map((optionGroup) => ({
      label: optionGroup.label,
      options: optionGroup.options.filter((option) => option.id !== selectedOption?.id),
    }))

    setOptions(updatedOptions)
  }

  return (
    <>
      {(isAdmin || newDrive) && (
        <Row className="search-row">
          <Col span={24} className="label">
            <label>{t('Search and choose users or groups to add')}</label>
          </Col>

          <Col span={10} className="input-group">
            <Form.Item name="participant">
              <AutoComplete
                allowClear
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
              />
            </Form.Item>
          </Col>

          <Col span={10} className="role-selector">
            <Form.Item name="role">
              <Select placeholder={t('Role of participant')} defaultOpen={false}>
                {Object.entries(DriveRole)
                  .filter(([key, value]) => value !== DriveRole.CREATOR)
                  .map(([key, value]) => (
                    <Select.Option key={key} value={value}>
                      <div className="d-flex d-flex-between d-flex-middle">
                        <span className="mr-05rem">{t(value)}</span>
                        <Tooltip
                          title={t(`Can ${key.replace('_', ' ').toLowerCase()}`)}
                          className="cursor-pointer"
                        >
                          <BiInfoCircle size="1em" className="info-color" />
                        </Tooltip>
                      </div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4} className="action-button">
            <Form.Item>
              <Button
                size="small"
                type="primary"
                icon={<IoIosAdd size="1.5rem" />}
                onClick={addDriveParticipant}
              >
                <span className="hideOnMobile">{t('Add', { ns: 'common' })}</span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
      <div className="participants-list">
        <Card title={t('Users', { ns: 'layout' })}>
          <DriveUsers newDrive={newDrive} />
        </Card>
        <Card title={t('Groups')}>
          <DriveGroups newDrive={newDrive} />
        </Card>
      </div>
    </>
  )
}

export default DriveFormAddParticipant
