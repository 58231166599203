import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { Alert, Spin, Badge, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import classNames from 'classnames'
import {
  markMessagesRead,
  saveChatScrollPosition,
  setScrolledToBottom,
} from './chatsSlice'
import MessageInput from './MessageInput'
import { BsArrowDownCircleFill } from 'react-icons/bs'
import ChatTitle from './ChatTitle'
import ChatDisconnectedAlert from './ChatDisconnectedAlert'
import FileUploadDialog, { FileUploadInterface, UploadMethod } from './FileUploadDialog'
import ChatMessages from './ChatMessages'
import './Chat.scss'
import ModifyChatDialog from './ModifyChatDialog'
import { ViewFile } from '../../Viewer/ViewerSlice'
import { UploadFile } from 'antd/es/upload/interface'
import { createSelector } from '@reduxjs/toolkit'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useChatScrollPositionMemory } from '../../../utils/hooks/useChatScrollPositionMemory'
import { Link } from 'react-router-dom'

interface ChatProps {
  currentSquadId: string
  currentChatId: string
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
}

function Chat({ currentSquadId, currentChatId, setOpenResponsiveDrawerMenu }: ChatProps) {
  const scrollbarRef = useRef<Scrollbars>(null)
  const selectChatDetails = (state: RootState) => state.chats.chatsDetails[currentChatId]
  const unreadMessagesCountSelector = createSelector(selectChatDetails, (chatDetails) => {
    return chatDetails?.unreadMessagesCount
  })
  const unreadMessagesCount = useSelector(unreadMessagesCountSelector)

  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const selectChat = (state: RootState) =>
    (state.chats.chats[currentSquadId] || []).find((c) => c.id === currentChatId)
  const chat = useSelector(selectChat)
  const chatToModify = useSelector((state: RootState) => state.chats.chatToModify)

  const minChunk = useRef(0)
  const selectChatMessages = (state: RootState) =>
    state.chats.chatsMessages[currentChatId]
  const chatMessagesSelector = createSelector(selectChatMessages, (chatMessages) => {
    if (chatMessages) {
      minChunk.current = parseInt(Object.keys(chatMessages.chunks)[0])
      // const chunksMessages = Object.values(chatMessages.chunks).flat()
      // return [...chunksMessages, ...chatMessages.live]
    }
    return chatMessages
  })
  const messages = useSelector(chatMessagesSelector)
  const squad = useSelector((state: RootState) => state.chatSquads.squads[currentSquadId])
  const username = useSelector((state: RootState) => state.auth.email)

  const scrolledToBottom = useSelector(
    (state: RootState) => state.chats.currentChat?.scrolledToBottom || false,
  )

  const selectMessageToEdit = createSelector(
    [selectChat, (state: RootState) => state.chats.messageInputDraft],
    (chat, messageInputDraft) => {
      return chat?.id ? messageInputDraft[chat.id]?.messageToEdit : undefined
    },
  )
  const messageToEdit = useSelector(selectMessageToEdit)

  const ws = useContext(WebSocketConnectionContext)
  const chatConnected = useSelector((state: RootState) => state.chatWebsocket.connected)
  const [filesInProgress, setFilesInprogress] = useState<UploadFile<any>[] | undefined>(
    undefined,
  )
  const fileUploadDialogRef = useRef<FileUploadInterface>(null)
  const chatsDetails = useSelector((state: RootState) => state.chats.chatsDetails)

  const [loading, setLoading] = useChatScrollPositionMemory(
    currentChatId,
    messages,
    scrolledToBottom,
    scrollbarRef,
  )

  function fetchMessages() {
    setLoading({ prevLimit: 0 })
    ws?.sendFetchChatMessages(currentSquadId, currentChatId)
  }

  useLayoutEffect(() => {
    if (chatConnected) {
      const lastMessage = chatsDetails[currentChatId]?.lastMessage

      if (!messages) {
        // Initial loading
        fetchMessages()
      } else {
        if (lastMessage) {
          fetchMessages()
        }
        ws?.sendMarkChatMessagesSeen(currentSquadId, currentChatId)
      }
    }
  }, [currentChatId, chatConnected])

  function sendMessage({
    text,
    files,
    repliedTo,
  }: {
    text: string
    files: string
    repliedTo?: string
  }) {
    messageToEdit
      ? ws?.editChatMessage(
          currentSquadId,
          currentChatId,
          messageToEdit.id,
          messageToEdit.createdAt,
          text,
          files,
          repliedTo,
        )
      : ws?.sendChatMessage(currentSquadId, currentChatId, text, files, repliedTo)
    if (chat?.id) {
      dispatch(markMessagesRead(chat.id))
    }
    setFilesInprogress(undefined)
  }

  function onFileUpload(type: UploadMethod) {
    //TODO: refactoring. Remove unnecessary variables, use either redux or an imperative handler of fileUploadDialogRef
    fileUploadDialogRef?.current?.openDeviceFolder(type)
  }

  function smoothScrollToBottom() {
    const chatMessagesContainer = document.getElementById('chat_messages_container')
    if (!chatMessagesContainer) return
    chatMessagesContainer.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  function onLastMessageSeen() {
    if (!chat) return
    dispatch(setScrolledToBottom(true))
    dispatch(markMessagesRead(chat.id))
  }

  return chat ? (
    <div
      className="Chat"
      onDragOver={(e) => {
        if (e.dataTransfer.types.includes('Files')) {
          onFileUpload('dragger')
        }
      }}
    >
      <div className="chat_header">
        {username && (
          <ChatTitle
            currentSquadId={currentSquadId}
            chat={chat}
            canUnselectChat={false}
            setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
          />
        )}
        <ChatDisconnectedAlert />
        {/* {chat.peerToPeer && (
          <Tooltip title={t('Call')}>
            <Link to=""></Link>
          </Tooltip>
        )} */}
      </div>
      <div className="chat_content">
        <Scrollbars
          style={{ flex: 1, backgroundColor: '#fff' }}
          ref={scrollbarRef}
          autoHide
          onScrollStop={() => {
            if (!scrollbarRef.current || !messages) return
            const { top, clientHeight, scrollHeight, scrollTop } =
              scrollbarRef.current.getValues()
            if (top > 0.99 /*  === 1 */ || clientHeight === scrollHeight)
              onLastMessageSeen()
            else if (scrolledToBottom) dispatch(setScrolledToBottom(false))
            dispatch(
              saveChatScrollPosition({ chatId: currentChatId, position: scrollTop }),
            ) //TODO: review, it calls rerender of ChatMessages
          }}
          onUpdate={({ top, clientHeight, scrollHeight }) => {
            if (!scrollbarRef.current || !messages) return
            if (clientHeight === scrollHeight) {
              onLastMessageSeen()
            } else if (top === 0 && minChunk.current > 0) {
              const { top, clientHeight, scrollHeight, scrollTop } =
                scrollbarRef.current.getValues()
              dispatch(
                saveChatScrollPosition({ chatId: currentChatId, position: scrollTop }),
              )

              setLoading({ prevLimit: scrollHeight })
              ws?.sendFetchChatMessages(
                currentSquadId,
                currentChatId,
                minChunk.current - 1,
              )
            }
          }}
        >
          {loading != undefined && (
            <div className="loading_new_chunk">
              <Spin size="large" />
            </div>
          )}
          <ChatMessages
            chat={chat}
            loading={loading != undefined}
            unreadMessagesCount={unreadMessagesCount}
          />
        </Scrollbars>
        <div className="chat_actions">
          <div
            className={classNames({
              'scroll-to-bottom': true,
              'scroll-to-bottom--shown': !scrolledToBottom,
            })}
          >
            <Badge count={unreadMessagesCount}>
              <BsArrowDownCircleFill
                color="#224160"
                size="2em"
                onClick={() => smoothScrollToBottom()}
              />
            </Badge>
          </div>

          {chat.archived ? (
            <Alert
              message={t('This conversation is archived because your interlocutor left')}
            />
          ) : (
            <MessageInput
              onSend={sendMessage}
              onUpload={() => onFileUpload('button')}
              previousFiles={
                messageToEdit?.files
                  ? (JSON.parse(messageToEdit?.files) as ViewFile[])
                  : undefined
              }
              t={t}
              currentChatId={currentChatId}
              encryptKey={chat.fileEncryptionKey}
              filesInProgress={filesInProgress}
              setFilesInprogress={setFilesInprogress}
            />
          )}
        </div>

        {chatToModify && (
          <ModifyChatDialog chat={chatToModify} currentSquadId={squad.id} />
        )}
      </div>
      <FileUploadDialog
        ref={fileUploadDialogRef}
        chat={chat}
        filesInProgress={filesInProgress}
        setFilesInprogress={setFilesInprogress}
      />
    </div>
  ) : (
    <Spin />
  )
}

export default Chat
