import React, { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import { Card, Spin } from 'antd'
import './Applications.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LOGO } from '../../assets/logos'
import ApplicationCard from './ApplicationCard'
import { HiOutlineViewGridAdd } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  fetchFavorites,
  idleDeleteFavoriteStatus,
  setAddFavoriteModalVisible,
} from './redux/applicationsSlice'
import AddFavoriteModal from './AddFavoriteModal'
import { ServiceUnavailableError } from '../../components/ServiceUnavailableError'
import { useAttemptsListener } from 'auxasphere-react-kit'
import { useToastContext } from '../../components/Toast/ToastContext'
import UpdateFavoriteModal from './UpdateFavoriteModal'

export interface InternApplicationInterface {
  id: string
  name: string
  icon: string
  action?: () => void
}

function Applications() {
  const { t } = useTranslation('applications')
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const favorites = useSelector((state: RootState) => state.applications.favorites)
  const { ToastOpen } = useToastContext()

  const fetchFavoritesStatus = useSelector(
    (state: RootState) => state.applications.fetchFavoritesStatus,
  )

  const deleteFavoriteStatus = useSelector(
    (state: RootState) => state.applications.deleteFavoriteStatus,
  )

  const AUXA_APPLICATIONS: InternApplicationInterface[] = [
    {
      id: 'Meetings',
      name: 'Meetings',
      icon: LOGO.logo_auxameetings,
      action: () => navigate('/upcoming-meetings'),
    },
    {
      id: 'Planning',
      name: 'Planning',
      icon: LOGO.logo_auxaplanning,
      action: () => navigate('/calendar'),
    },
    {
      id: 'Team',
      name: 'Team',
      icon: LOGO.logo_auxateam,
      action: () => navigate('/messages'),
    },
    {
      id: 'Drive',
      name: 'Drive',
      icon: LOGO.logo_auxadrive,
      action: () => navigate('/drives/home'),
    },
    {
      id: 'Transfer',
      name: 'Transfer',
      icon: LOGO.logo_auxatransfer,
    },
    {
      id: 'Auxadoc',
      name: 'Auxadoc',
      icon: LOGO.logo_auxadoc,
      action: () => window.open('https://medecin-demo.temma.care', '_blank'),
    },
  ]

  useEffect(() => {
    dispatch(fetchFavorites())
  }, [])

  useAttemptsListener(
    [
      [
        deleteFavoriteStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Favorite was successfully deleted.'),
              type: 'success',
            })
          },
          unknown_error: () => {
            ToastOpen({
              message: t('Error deleting favorite.'),
              type: 'error',
            })
          },
        },
      ],
    ],
    () => dispatch(idleDeleteFavoriteStatus()),
  )

  return (
    <>
      <Layout
        footer={true}
        background={true}
        content={
          <div className="applications">
            <div className="applications-content applications-content--desktop">
              <div className="row-container">
                <h1 className="title">{t('My Auxa solutions')}</h1>
                <div className="row">
                  {AUXA_APPLICATIONS.map((app, index) => (
                    <div key={index} onClick={app.action}>
                      <ApplicationCard application={app} intern={true} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="row-container">
                <h1 className="title">{t('My workspace')}</h1>
                {fetchFavoritesStatus === 'success' && (
                  <div className="row">
                    {favorites?.map((favorite, index) => (
                      <div key={index}>
                        <ApplicationCard application={favorite} intern={false} />
                      </div>
                    ))}
                    <div
                      className="ApplicationCard"
                      onClick={() => dispatch(setAddFavoriteModalVisible(true))}
                    >
                      <Card className="card" hoverable>
                        <HiOutlineViewGridAdd size="5em" className="add mt-0_2rem" />
                      </Card>
                      <h3 className="name">{t('Add', { ns: 'common' })}</h3>
                    </div>
                  </div>
                )}

                {fetchFavoritesStatus === 'loading' && (
                  <div className="ml-2rem d-flex d-flex-middle h-3rem">
                    <Spin size="large" />
                  </div>
                )}

                {fetchFavoritesStatus === 'SERVICE_UNAVAILABLE' ||
                  (fetchFavoritesStatus === 'unknown_error' && (
                    <ServiceUnavailableError />
                  ))}
              </div>
            </div>
            <AddFavoriteModal />
            <UpdateFavoriteModal />
          </div>
        }
      />
      {/* <ModalUnauthorizedAccess
        unAuthorizedAcces={unAuthorizedAcces}
        organisationName={organisationName}
      /> */}
    </>
  )
}

export default Applications
