import React, { useEffect, useState } from 'react'
import { useToastContext } from '../../components/Toast/ToastContext'
import { setDesktopNotification } from './redux/accountSlice'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Divider, Form, Switch } from 'antd'

import './DesktopNotificationSettings.scss'

function DesktopNotificationSettings() {
  const { t } = useTranslation('account')
  const { ToastOpen } = useToastContext()
  const isDesktopNotificationEnabled = useSelector(
    (state: RootState) => state.account.IsDesktopNotificationEnabled,
  )
  const dispatch = useDispatch<AppDispatch>()
  const [form] = Form.useForm()

  const [desktopNotificationEnabled, setDesktopNotificationEnabled] = useState(
    isDesktopNotificationEnabled,
  )

  useEffect(() => {
    askPermission()
    form.setFieldsValue({
      desktopNotification: isDesktopNotificationEnabled,
    })
  }, [])

  const handleDesktopNotificationChange = (checked: boolean) => {
    setDesktopNotificationEnabled(checked)
    form.submit()
  }

  const askPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        dispatch(setDesktopNotification({ status: true }))
      } else if (permission === 'denied') {
        form.setFieldsValue({
          desktopNotification: false,
        })
        ToastOpen({
          message:
            t(`Desktop notification disabled for`) +
            ` ${window.location.origin}. ` +
            t(`To reactivate them, go to your navigator settings`),
          type: 'error',
        })
        dispatch(setDesktopNotification({ status: false }))
      }
    })
  }

  const onFinish = (values: any) => {
    if (values) {
      askPermission()
    }
  }

  return (
    <>
      <div>
        <div className="layout-header-bottom">
          <h4 className="layout-list-header">{t('Desktop Notification')}</h4>
        </div>
      </div>
      <Divider />
      <Form form={form} className="account-modify-form mb-1rem" onFinish={onFinish}>
        <Row gutter={[24, 8]}>
          <Col span={24} className="account-notification">
            <Form.Item
              label={t('Enable desktop notification')}
              name="desktopNotification"
              valuePropName="checked"
            >
              <Switch
                checked={desktopNotificationEnabled}
                onChange={handleDesktopNotificationChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DesktopNotificationSettings
